#mapDiv .leaflet-container {
  position: relative;
  margin-left: unset;
  width: 100%;
  height: 75vh;
  z-index: 1;
}
#mapDiv.full {
  height: 100vh;
}
#mapDiv.full .leaflet-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  margin-left: -7%;
}
#shrink {
  display: none;
}

#mapDiv.full #shrink {
  display: block;
}
#mapDiv.full #expand {
  display: none;
}

.map-main-div {
  height: 80vh;
}

button.leaflet-control {
  /* margin-right: 20px; */
}

.map-loading {
  opacity: 0.5;
}
