.heading-img-div img {
  max-width: 400px;
  display: flex;
  margin: 0 auto;
}

.experience-card-logo-div .experience-card-logo {
  width: 270px;
}
.travel-main .experience-card-body-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 768px) {
  .experience-card-logo {
    width: 300px;
  }
}
